import { toDisplayString as _toDisplayString, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "numeric-virtual-keyborad-wrap" }
const _hoisted_2 = { class: "numeric-virtual-keyborad-input-field" }
const _hoisted_3 = { class: "numeric-virtual-keyborad-keys-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: "numeric-virtual-keyborad",
    onClick: _cache[13] || (_cache[13] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.hadleOnClose && _ctx.hadleOnClose(...args)), ["self"]))
  }, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, _toDisplayString(_ctx.formatedInputValue), 1),
      _createVNode("div", _hoisted_3, [
        _createVNode("div", {
          class: "numeric-virtual-keyborad-key",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleClicked('1')))
        }, " 1 "),
        _createVNode("div", {
          class: "numeric-virtual-keyborad-key",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleClicked('2')))
        }, " 2 "),
        _createVNode("div", {
          class: "numeric-virtual-keyborad-key",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleClicked('3')))
        }, " 3 "),
        _createVNode("div", {
          class: "numeric-virtual-keyborad-key",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleClicked('4')))
        }, " 4 "),
        _createVNode("div", {
          class: "numeric-virtual-keyborad-key",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.handleClicked('5')))
        }, " 5 "),
        _createVNode("div", {
          class: "numeric-virtual-keyborad-key",
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.handleClicked('6')))
        }, " 6 "),
        _createVNode("div", {
          class: "numeric-virtual-keyborad-key",
          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.handleClicked('7')))
        }, " 7 "),
        _createVNode("div", {
          class: "numeric-virtual-keyborad-key",
          onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.handleClicked('8')))
        }, " 8 "),
        _createVNode("div", {
          class: "numeric-virtual-keyborad-key",
          onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.handleClicked('9')))
        }, " 9 "),
        _createVNode("div", {
          class: "numeric-virtual-keyborad-key",
          onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.handleClicked('x')))
        }, " X "),
        _createVNode("div", {
          class: "numeric-virtual-keyborad-key",
          onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.handleClicked('0')))
        }, " 0 "),
        _createVNode("div", {
          class: "numeric-virtual-keyborad-key",
          onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.handleClicked('xx')))
        }, " 完成 ")
      ])
    ])
  ]))
}