import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Loby from '../views/loby/index.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'loby',
        component: Loby
    },
    {
        path: '/select-server-line',
        name: 'select-server-line',
        component: () => import('@/views/SelectServiceLine.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login/index.vue')
    },
    {
        path: '/game',
        name: 'game',
        component: () => import('@/views/desk/index.vue')
    },
    {
        path: '/multi-betting',
        name: 'multi-betting',
        component: () => import('@/views/multi-view/index.vue')
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
