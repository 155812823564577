import { DeskData } from '@/datamodel/deskdata'
import { TableLimit } from '@/datamodel/types'
import { toMoneyFormat } from '@/utils'
import { Options, Vue } from 'vue-class-component'
import { useI18n } from 'vue-i18n'
import { Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

@Options({})
export class HeaderMixin extends Vue {
    iN = useI18n().t
    @Prop({ default: true })
    isOnLoby = true

    @Prop({ default: false }) isMultiView!: boolean
    //#region Vuex
    @Getter('betCoin') betCoin!: number
    @Getter('userId') userId!: string
    @Getter('currentDesk') currentDesk!: DeskData
    @Getter('deskLimit') deskLimit!: TableLimit

    //#endregion

    //#region Helpers
    goToLoby(): void {
        this.$router.push({ name: 'loby' })
    }

    showSwitchTable(): void {
        this.$emit('show-switch-table')
    }
    //#endregion

    // get coin(): string {
    //     return toMoneyFormat(this.betCoin)
    // }

    get min(): number {
        if (this.deskLimit) return this.deskLimit.Min || 0
        return 0
    }

    get max(): number {
        if (this.deskLimit) return this.deskLimit.Max || 0
        return 0
    }

    get title(): string {
        if (this.isMultiView) return '自选多合一'

        if (this.currentDesk.gameType === 1) {
            return `百家乐 ${this.currentDesk.deskId} 号桌`
        } else {
            return `龙虎 ${this.currentDesk.deskId} 号桌`
        }
    }
}
