import { ACT } from '@/utils/constants'
import { BaseParams } from './BaseParams'

export class MultipleDeskParams extends BaseParams {
    gameType: number
    sessionID: string
    username: string
    constructor(gameType: number, sessionId: string, username: string) {
        super(ACT.MULTIPLE_DESK_INFO)
        this.gameType = gameType
        this.sessionID = sessionId
        this.username = username
    }
}
