export class ResponseData {
    error?: string
    data?: string
    optinal = -1

    /**
     *
     */
    constructor(arg: { error?: string; data?: string; optional?: number }) {
        const { error, data, optional } = arg
        this.error = error
        this.data = data

        if (optional) {
            this.optinal = optional
        }
    }
}
