import { AxiosPromise, CancelTokenSource } from 'axios'
import service from '@/utils/request'
import { MultipleBetDeskInfo } from '@/datamodel/urlparams/MultipleBetDeskInfo'
import { AxiosSource } from '.'

export function multibetTables(
    model: MultipleBetDeskInfo
): AxiosPromise<string> {
    return service({
        method: 'POST',
        params: model,
        cancelToken: AxiosSource.Source.token
    })
}
