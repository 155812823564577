import { DeskData } from '@/datamodel/deskdata'
import { ResponseData } from '@/datamodel/response-data'
import { MultipleDeskParams } from '@/datamodel/urlparams/MultipleDeskParams'
import { Options, Vue } from 'vue-class-component'
import { useI18n } from 'vue-i18n'
import { Action, Getter } from 'vuex-class'

@Options({})
export class LobyMixin extends Vue {
    selectedTab = 3
    hasPendingRequest = false
    selectedIndex = -1
    showPasswordForm = false
    showHistory = false
    showSettings = false
    iN = useI18n().t

    serviceTimer: number | boolean = false

    desks: Array<DeskData> = new Array<DeskData>()
    //#region Vuex
    @Getter('lobyNotice') notice!: string
    @Getter('sessionId') sessionId!: string
    @Getter('lobyInfoKey') infoKey!: number
    @Getter('userId') userId!: string

    @Action('getMultipleDesk') getMultipleDesk!: (
        model: MultipleDeskParams
    ) => Promise<ResponseData>
    @Action('showLoading') showLoading!: () => void
    @Action('hideLoading') hideLoading!: () => void
    @Action('destroySession') destroySession!: () => void
    //#endregion

    //#region Vue Methods
    mounted(): void {
        this.fetchData()
    }

    beforeUnmount(): void {
        this.endService()
    }

    //#endregion

    //#region Helpers
    fetchData(): void {
        this.hasPendingRequest = true
        // if (!this.loadingIsVisible) this.showLoading()
        this.showLoading()
        const model = new MultipleDeskParams(3, this.sessionId, this.userId)

        this.getMultipleDesk(model)
            .then((response: ResponseData) => {
                this.hideLoading()
                if (response.error) {
                    this.hasPendingRequest = false
                    if (response.error === '2') {
                        // session is expired logut user and redirect it to login
                        this.endService()
                        this.destroySession()
                        this.$router.push({ name: 'login' })
                    } else {
                        this.startService()
                    }
                } else {
                    this.hasPendingRequest = false
                    if (response.data) {
                        this.desks = DeskData.ParseList(response.data)
                    }

                    this.startService()
                }
            })
            .catch(() => {
                this.hasPendingRequest = false

                // wait for 1 sec before refetching data
                setTimeout(() => {
                    this.fetchData()
                }, 1000)
            })
    }

    startService(): void {
        this.serviceTimer = setInterval(() => {
            if (!this.hasPendingRequest) {
                this.hasPendingRequest = true

                const model = new MultipleDeskParams(
                    3,
                    this.sessionId,
                    this.userId
                )
                this.getMultipleDesk(model)
                    .then((response: ResponseData) => {
                        if (response.error) {
                            if (response.error == '2') {
                                // session is expired logut user and redirect it to login
                                this.endService()
                                this.destroySession()
                                this.$router.push({ name: 'login' })
                            } else {
                                this.hasPendingRequest = false
                            }
                        } else {
                            if (response.data) {
                                this.desks = DeskData.ParseList(response.data)
                            }
                            this.hasPendingRequest = false
                        }
                    })
                    .catch(() => {
                        this.hasPendingRequest = false
                    })
            }
        }, 3000)
    }

    endService(): void {
        if (typeof this.serviceTimer === 'number') {
            clearInterval(this.serviceTimer)
            this.serviceTimer = false
        }
    }

    selectItem(deskId: number): void {
        if (this.selectedIndex === deskId) {
            this.selectedIndex = -1
            return
        }

        this.selectedIndex = deskId
    }
    //#endregion

    //#region Click Events
    onSelectTab(index: number): void {
        if (this.selectedTab !== index) {
            this.selectedTab = index
        }
    }
    //#endregion

    //#region Computed Properties
    get listData(): Array<DeskData> {
        if (this.selectedTab === 1) {
            return this.desks.filter((s: DeskData) => {
                return s.gameType === 1
            })
        } else if (this.selectedTab === 2) {
            return this.desks.filter((s: DeskData) => {
                return s.gameType === 2
            })
        } else {
            return this.desks
        }
    }
    //#endregion
}
