/* eslint-disable @typescript-eslint/ban-ts-comment */
// import 'babel-polyfill'

import '@/utils/string-ext'
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { store, key } from './store'

// import { VueAgile } from 'vue-agile'
import VCalendar from 'v-calendar'

import '@/assets/styles.css'

import '@/styles/index.scss'

import './permission'

import mitt from 'mitt'

const emitter = mitt()

import i18n from './utils/i18n'

// @ts-ignore
NodePlayer.debug(false)
// @ts-ignore
NodePlayer.load(() => {
    const app = createApp(App)
    app.config.globalProperties.emitter = emitter

    // console.log(app.config)
    app.use(i18n)
        .use(store, key)
        .use(router)
        .use(VCalendar, {})
        // .component('vue-agile', VueAgile)
        .mount('#app')
})
