<template>
    <div class="mobile-header-root"></div>
</template>

<script>
import { Mixins } from 'vue-property-decorator'
import { HeaderMixin } from './mixin'

export default class MobileHeader extends Mixins(HeaderMixin) {}
</script>

<style>
</style>