import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pc_header = _resolveComponent("pc-header")
  const _component_mobile_header = _resolveComponent("mobile-header")

  return (_ctx.device.display !== 1)
    ? (_openBlock(), _createBlock(_component_pc_header, {
        key: 0,
        onOnShowLimit: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-show-limit')))
      }))
    : (_openBlock(), _createBlock(_component_mobile_header, { key: 1 }))
}