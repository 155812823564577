import { ACT } from '@/utils/constants'
import { BaseParams } from './BaseParams'

export class BetHistoryParams extends BaseParams {
    sday: string
    eday: string
    username: string

    constructor(username: string, startDate: string, endDate: string) {
        super(ACT.GET_LOG)
        this.username = username
        this.sday = startDate
        this.eday = endDate
    }
}
