import { createI18n } from 'vue-i18n'
import { getBrowserLanguage, getCookieValue } from '.'
import { Token } from './constants'

const messages = {
    en: {
        lang: {
            zh: '简体中文',
            en: 'English',
            kor: '한국어'
        },
        languagegialog: {
            title: 'Language'
        },
        common: {
            confirm: 'Confirm',
            cancel: 'Cancel',
            allgames: 'All Games',
            baccarat: 'Baccarat',
            dragontiger: 'Dragon Tiger',
            search: 'Search',
            norecordfound: 'No Record Found',
            livegames: 'Live Games',
            video: 'Video',
            enterthistable: 'GO PLAY',
            fullscreen: 'Full Screen',
            lobby: 'Loby'
        },
        login: {
            username: 'Username',
            password: 'Password',
            code: 'Verify Code',
            login: 'Login',
            trial: 'Trial',
            usernameplaceholder: 'Please enter your username',
            passwordplaceholder: 'Enter your password'
        },
        tables: {
            startgame: 'Play',
            selectlimit: 'SELECT',
            limit: 'Table Limit',
            youatthistable: 'You are at this table',
            multibet: 'Multi Tables'
        },
        desk: {
            startbet: 'START BET',
            nobet: 'STOP BET',
            shoe: 'Shoe',
            limit: 'Limit',
            latestnews: 'Latest News',
            servicehotline: 'Service Hotline',
            gamenumber: 'Game No.'
        },
        setting: {
            betrecord: 'Bet Records',
            rule: 'Game Rules',
            changepassword: 'Change Password',
            customerservice: 'Customer Service',
            soundeffect: 'Sound',
            signout: 'Sign out'
        },
        changepassword: {
            oldpassword: 'Old password',
            newpassword: 'New password',
            confirmpassword: 'Confirm password'
        },
        serverselection: {
            lineSelection: 'Line Selection',
            p1: 'Line is green',
            p2: 'You can click directly to enter. Red',
            p3: 'Indicates that the line is unavailable,',
            p4:
                'If all three lines are unavailable, please contact online customer service to obtain more lines, enter the obtained line address and click to enter the game. I wish you a happy game.',
            line1: 'Line1',
            line2: 'Line2',
            line3: 'Line3',
            customline: 'Custom Lines',
            enter: 'Enter',
            lineerror: 'IP is invalid'
        }
    },
    zh: {
        lang: {
            zh: '简体中文',
            en: 'English',
            kor: '한국어'
        },
        languagegialog: {
            title: '语言设置'
        },
        common: {
            confirm: '确 认',
            cancel: '取消',
            allgames: '全部游戏',
            baccarat: '百家乐',
            dragontiger: '龙虎',
            search: '查询',
            norecordfound: ' 没有查询到记录',
            livegames: '现场游戏',
            video: '视频',
            enterthistable: '进入此桌',
            fullscreen: '全屏幕',
            lobby: '返回大厅'
        },
        login: {
            username: '用户名称',
            password: '密码',
            code: '验证码',
            login: '登 入',
            trial: '试玩',
            usernameplaceholder: '请输入您的用户名',
            passwordplaceholder: '输入您的密码'
        },
        tables: {
            startgame: '开始游戏',
            selectlimit: '选择限红',
            limit: '限红',
            youatthistable: '您在此桌',
            multibet: '自选多合一'
        },
        desk: {
            startbet: '开始接受投注',
            nobet: '停止投注',
            shoe: '靴局',
            limit: '限红',
            latestnews: '最新消息',
            servicehotline: '服务热线',
            gamenumber: '游戏编号'
        },
        setting: {
            betrecord: '游戏记录',
            rule: '游戏规则',
            changepassword: '修改密码',
            customerservice: '线上客服',
            soundeffect: '声音效果',
            signout: '登出'
        },
        changepassword: {
            oldpassword: '原始密码',
            newpassword: '新密码',
            confirmpassword: '确认密码'
        },
        serverselection: {
            lineSelection: '线路选择',
            p1: '线路为绿色',
            p2: '可直接点击进入，红色',
            p3: '代表该线路不可用，',
            p4:
                '如三个线路均不可用，请联系在线客服获取更多线路，并输入所获取的线路地址点击进入，即可游戏，祝您游戏愉快。',
            line1: '线路一',
            line2: '线路二',
            line3: '线路三',
            customline: '自定义线路',
            enter: '进入',
            lineerror: 'ip 地址错误'
        }
    },
    kor: {
        lang: {
            zh: '简体中文',
            en: 'English',
            kor: '한국어'
        },
        languagegialog: {
            title: '언어 설정'
        },
        common: {
            confirm: '확인하다',
            cancel: '취소',
            allgames: '모든 게임들',
            baccarat: '바카라',
            dragontiger: '드래곤 타이거',
            search: '찾다',
            norecordfound: '기록을 찾을 수 없습니다',
            livegames: '라이브 게임',
            video: '동영상',
            enterthistable: '놀다',
            fullscreen: '전체 화면',
            lobby: '로비'
        },
        login: {
            username: '사용자 이름',
            password: '비밀번호',
            code: '코드 확인',
            login: '로그인',
            trial: '재판',
            usernameplaceholder: '사용자 이름을 입력하세요',
            passwordplaceholder: '비밀번호를 입력하세요'
        },
        tables: {
            startgame: '놀다',
            selectlimit: '선택하다',
            limit: '테이블 한도',
            youatthistable: '당신은 이 테이블에 있습니다',
            multibet: '멀티 테이블'
        },
        desk: {
            startbet: '베팅 시작',
            nobet: '베팅 중지',
            shoe: '구두',
            limit: '한계',
            latestnews: '최근 뉴스',
            servicehotline: '서비스 핫라인',
            gamenumber: '게임 번호'
        },
        setting: {
            betrecord: '베팅 기록',
            rule: '게임 규칙',
            changepassword: '비밀번호 변경',
            customerservice: '고객 서비스',
            soundeffect: '음향 효과',
            signout: '로그아웃'
        },
        changepassword: {
            oldpassword: '기존 비밀번호',
            newpassword: '새 비밀번호',
            confirmpassword: '비밀번호 확인'
        },
        serverselection: {
            lineSelection: '라인 선택',
            p1: '선이 녹색입니다',
            p2: '클릭하면 바로 들어갈 수 있습니다, 빨간색',
            p3: '회선을 사용할 수 없음을 나타냅니다.',
            p4:
                '세 라인을 모두 사용할 수 없는 경우 온라인 고객 서비스에 문의하여 더 많은 라인을 확보하고 획득한 라인 주소를 입력한 후 클릭하여 게임에 입장하시기 바랍니다. ',
            line1: '1번째 줄',
            line2: '라인 2',
            line3: '3번째 줄',
            customline: '맞춤 라인',
            입력하다: '입력하다',
            lineerror: 'IP 주소 오류'
        }
    }
}

const i18n = createI18n({
    legacy: false,
    locale: getCookieValue(Token.LANG) || getBrowserLanguage(),
    fallbackLocale: 'zh',
    globalInjection: true,
    warnHtmlInMessage: false,
    warnHtmlMessage: false,
    messages
})

export default i18n
