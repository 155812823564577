
import { Prop, Watch } from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component'
import {
    drawRoadMap,
    drawRoadMapGrid,
    drawRoadMapGrid1,
    drawRoadMapGrid2,
    drawSingleGridMap,
    parseRoadMap
} from '@/components/roadmap/utils'
import { RoadMapData, RoadMapProperties } from './data-model'
import { DISPLAY, GAMETYPE, RoadType } from './data-model/types'
import { EventBuss } from '@/utils/constants'

@Options({})
export default class RoadMapView extends Vue {
    @Prop({ default: DISPLAY.default_horizontal })
    display!: number

    @Prop({ default: '' })
    mapData!: string

    @Prop({ default: GAMETYPE.baccarat })
    gameType!: number

    isActive = false

    @Prop({ default: false })
    showActiveAndInActive!: boolean

    @Prop({ default: false })
    predictionEnabled!: boolean

    @Prop({ default: 13 })
    beadRowsCount!: number

    roadMapData!: RoadMapData
    readyToRender = false

    //#region  Prediction Variables
    nextRed!: RoadMapData
    nextBlue!: RoadMapData
    predictionTimer: number | boolean = false
    predictionType = 0
    predictionCount = 9
    predictionBlink = 9
    //#endregion

    beforeMount(): void {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        this.emitter.on(EventBuss.RELOAD_MAP, this.UpdateRoadMapData)
    }
    mounted(): void {
        this.readyToRender = true
        drawRoadMapGrid(this.$refs['map_canvas'] as HTMLCanvasElement)
        this.drapMap()
    }

    beforeUnmount(): void {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        this.emitter.off(EventBuss.RELOAD_MAP, this.UpdateRoadMapData)
    }

    UpdateRoadMapData(): void {
        this.drapMap()
    }

    drapMap(): void {
        if (!this.readyToRender) return

        if (this.display === DISPLAY.default_horizontal) {
            return this.drawDefaultHorizontalMap(this.roadMapData)
        } else if (this.display === DISPLAY.default_vertical) {
            return this.drawDefaultVerticalMap(this.roadMapData)
        } else if (this.display === DISPLAY.bigeye) {
            return this.drawOnlyBigMap(this.roadMapData)
        } else if (this.display === DISPLAY.no_bead_vertical) {
            return this.drawNoBeadVertical(this.roadMapData)
        } else if (this.display === DISPLAY.no_bead_horizontal) {
            return this.drawNoBeadHorizontal(this.roadMapData)
        }
    }

    Predict(color: number): void {
        if (!this.predictionEnabled) return

        this.predictionType = color
        this.predictionCount = this.predictionBlink

        if (typeof this.predictionTimer !== 'number') {
            this.predictionTimer = setInterval(() => {
                // pre
                if (this.predictionCount % 2 !== 0) {
                    if (this.predictionType === 1 && this.nextRed) {
                        this.handlePrediction(this.nextRed)
                    } else if (this.predictionType === 2 && this.nextBlue) {
                        this.handlePrediction(this.nextBlue)
                    }
                } else {
                    if (this.roadMapData) {
                        if (this.predictionType === 1 && this.nextRed) {
                            this.handlePrediction(this.nextRed, true)
                        } else if (this.predictionType === 2 && this.nextBlue) {
                            this.handlePrediction(this.nextBlue, true)
                        } else {
                            this.handlePrediction(this.roadMapData)
                        }
                    }
                }

                if (
                    this.predictionCount === 0 &&
                    typeof this.predictionTimer === 'number'
                ) {
                    clearInterval(this.predictionTimer)
                    this.predictionTimer = false
                }

                this.predictionCount--
            }, 200)
        }
    }

    drawDefaultHorizontalMap(
        mapData: RoadMapData | undefined,
        isPre = false
    ): void {
        const prop = drawRoadMapGrid(
            this.$refs['map_canvas'] as HTMLCanvasElement,
            this.beadRowsCount
        )

        if (prop && mapData) {
            prop.isPre = isPre
            const { beads, bigs, bigeyes, smalls, cockroachs } = mapData
            const { width } = prop

            prop.colWidth = prop.height / 6
            prop.colNum = this.beadRowsCount
            drawRoadMap(prop, beads, RoadType.BEAD, 0, 0, this.gameType)

            prop.colNum = Math.floor(
                (width - this.beadRowsCount * prop.colWidth) /
                    (prop.colWidth / 2)
            )

            // this will serve as x offset of big big,bigeys, small roadmap
            const offsetX1 = prop.colWidth * this.beadRowsCount
            drawRoadMap(prop, bigs, RoadType.BIG, 0, offsetX1)
            const offsetY1 = prop.colWidth * 3

            prop.colNum = prop.colNum * 2
            drawRoadMap(prop, bigeyes, RoadType.BIGEYE, offsetY1, offsetX1)

            const offsetY2 = prop.colWidth * 4.5

            prop.colNum = Math.floor(prop.colNum / 2)
            drawRoadMap(prop, smalls, RoadType.SMALL, offsetY2, offsetX1)
            const offsetX2 = offsetX1 + (prop.colNum * prop.colWidth) / 4
            drawRoadMap(
                prop,
                cockroachs,
                RoadType.COCKROACH,
                offsetY2,
                offsetX2
            )
        }
    }

    drawDefaultVerticalMap(data: RoadMapData, isPre = false): void {
        const prop = drawRoadMapGrid2(
            this.$refs['map_canvas'] as HTMLCanvasElement
        )

        if (prop && data) {
            prop.isPre = isPre
            const { beads, bigs, bigeyes, smalls, cockroachs } = data
            const { width, colWidth } = prop

            prop.colNum = Math.floor(width / colWidth)
            drawRoadMap(prop, beads, RoadType.BEAD, 0, 0, this.gameType)

            const offsetY1 = colWidth * 6
            const offsetY2 = offsetY1 + colWidth * 3
            const offsetY3 = offsetY2 + colWidth * 1.5

            prop.colNum = Math.floor(width / (colWidth / 2))
            drawRoadMap(prop, bigs, RoadType.BIG, offsetY1, 0)

            prop.colNum = prop.colNum * 2
            drawRoadMap(prop, bigeyes, RoadType.BIGEYE, offsetY2, 0)

            prop.colNum = Math.floor(prop.colNum / 2)
            drawRoadMap(prop, smalls, RoadType.SMALL, offsetY3, 0)

            const offsetX1 = prop.colNum * (prop.colWidth / 4)
            drawRoadMap(
                prop,
                cockroachs,
                RoadType.COCKROACH,
                offsetY3,
                offsetX1
            )
        }
    }

    drawOnlyBigMap(data: RoadMapData, isPre = false): void {
        const prop = drawSingleGridMap(
            this.$refs['map_canvas'] as HTMLCanvasElement
        )

        if (prop && data) {
            const { bigs } = data
            prop.isPre = isPre
            prop.colWidth = prop.colWidth * 2
            prop.colNum = Math.floor(prop.width / (prop.colWidth / 2))
            drawRoadMap(prop, bigs, RoadType.BIG, 0, 0)
        }
    }

    drawNoBeadVertical(data: RoadMapData, isPre = false): void {
        const prop = drawRoadMapGrid1(
            this.$refs['map_canvas'] as HTMLCanvasElement
        )
        if (prop && data) {
            const { bigs, bigeyes, smalls, cockroachs } = data
            prop.isPre = isPre
            prop.colNum = Math.floor(prop.width / prop.colWidth) * 2
            const oldColNum = prop.colNum
            drawRoadMap(prop, bigs, RoadType.BIG, 0, 0)
            prop.colNum = prop.colNum * 2
            drawRoadMap(prop, bigeyes, RoadType.BIGEYE, prop.colWidth * 3, 0)
            prop.colNum = oldColNum
            drawRoadMap(prop, smalls, RoadType.SMALL, prop.colWidth * 4.5, 0)
            drawRoadMap(
                prop,
                cockroachs,
                RoadType.COCKROACH,
                prop.colWidth * 4.5,
                prop.colNum * (prop.colWidth / 4)
            )
        }
    }

    drawNoBeadHorizontal(data: RoadMapData, isPre = false): void {
        let prop: RoadMapProperties | undefined

        if (this.showActiveAndInActive && this.isActive) {
            const style = { stroke: '#999999', fill: '#777777' }
            prop = drawSingleGridMap(
                this.$refs['map_canvas'] as HTMLCanvasElement,
                style
            )
        } else {
            prop = drawSingleGridMap(
                this.$refs['map_canvas'] as HTMLCanvasElement
            )
        }

        if (prop && data) {
            const { bigs, bigeyes, smalls, cockroachs } = data
            prop.isPre = isPre
            prop.colWidth = prop.colWidth * 2
            prop.colNum = Math.floor(prop.width / prop.colWidth)
            const oldColNum = prop.colNum

            drawRoadMap(prop, bigs, RoadType.BIG, 0, 0)

            prop.colNum = prop.colNum * 2
            drawRoadMap(
                prop,
                bigeyes,
                RoadType.BIGEYE,
                0,
                (oldColNum / 2) * prop.colWidth
            )

            prop.colNum = oldColNum

            drawRoadMap(
                prop,
                smalls,
                RoadType.SMALL,
                prop.height / 2,
                (oldColNum / 2) * prop.colWidth
            )

            drawRoadMap(
                prop,
                cockroachs,
                RoadType.COCKROACH,
                prop.height / 2,
                (oldColNum / 2) * prop.colWidth +
                    ((oldColNum / 2) * prop.colWidth) / 2
            )
        }
    }

    //#region Private HelPers
    private handlePrediction(data: RoadMapData, isPre = false): void {
        if (this.display === DISPLAY.default_horizontal)
            this.drawDefaultHorizontalMap(data, isPre)
        else if (this.display === DISPLAY.default_vertical) {
            this.drawDefaultVerticalMap(data, isPre)
        } else if (this.display === DISPLAY.bigeye) {
            this.drawOnlyBigMap(data, isPre)
        } else if (this.display === DISPLAY.no_bead_vertical) {
            this.drawNoBeadVertical(data, isPre)
        } else if (this.display === DISPLAY.no_bead_horizontal) {
            this.drawNoBeadHorizontal(data, isPre)
        }
    }
    //#endregion

    @Watch('mapData', { immediate: true })
    OnUpdateDate(value: string): void {
        this.roadMapData = parseRoadMap(value)

        this.nextRed = parseRoadMap(`${value}10#`)
        this.nextBlue = parseRoadMap(`${value}30#`)

        this.$emit(
            'road-map-data',
            this.roadMapData,
            this.nextRed,
            this.nextBlue
        )

        if (this.readyToRender && typeof this.predictionTimer !== 'number')
            this.drapMap()
    }

    // @Watch('isActive')
    // UpdateState(): void {
    //     if (this.showActiveAndInActive && this.readyToRender) {
    //         this.drapMap()
    //     }
    // }
}
