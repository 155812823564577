
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

@Options({})
export default class TimerLoadingBar extends Vue {
    @Prop({ default: -1 })
    count!: number
    maxValue = 50

    isReset = false

    @Watch('count', { immediate: true })
    updateFill(value: number): void {
        const fill = this.$refs['fill'] as HTMLDivElement
        const border = this.$refs['border'] as HTMLDivElement

        if (fill && border && value > -1) {
            const wrapWidth =
                border.getBoundingClientRect().width / this.maxValue

            fill.style.width =
                ((wrapWidth * this.count) /
                    border.getBoundingClientRect().width) *
                    100 +
                '%'

            setTimeout(() => {
                this.isReset = false
            }, 1000)
        } else if (fill && border && value === -1) {
            this.isReset = true
            fill.style.width = '0px'
        } else if (fill && border) {
            this.isReset = true
            fill.style.width = '0px'
        }
    }
}
