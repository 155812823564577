
import { toMoneyFormat } from '@/utils'
import { Options, Vue } from 'vue-class-component'

@Options({})
export default class NumericVirtualKeyboard extends Vue {
    inputValue = 0
    handleClicked(
        keyinput:
            | '1'
            | '2'
            | '3'
            | '4'
            | '5'
            | '6'
            | '7'
            | '8'
            | '9'
            | '0'
            | 'x'
            | 'xx'
    ): void {
        switch (keyinput) {
            case 'x':
                {
                    if (this.inputValue > 0) {
                        let _newVal = parseInt(
                            String(this.inputValue).slice(0, -1)
                        )
                        if (isNaN(_newVal)) _newVal = 0
                        this.inputValue = _newVal
                    }
                }
                break
            case 'xx': {
                this.$emit('confirm', this.inputValue)

                break
            }
            case '1':
            case '2':
            case '3':
            case '4':
            case '5':
            case '6':
            case '7':
            case '8':
            case '9':
            case '0': {
                if (String(this.inputValue).length > 8) {
                    // toast a message here
                    break
                }
                if (this.inputValue > 0) {
                    this.inputValue = parseInt(
                        [this.inputValue.toString(), keyinput].join('')
                    )
                } else {
                    this.inputValue = parseInt(keyinput)
                }
                break
            }

            default:
                break
        }
    }

    hadleOnClose() {
        this.$emit('close')
    }

    get formatedInputValue(): string {
        return toMoneyFormat(this.inputValue)
    }
}
