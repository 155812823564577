
import { toMoneyFormat } from '@/utils'
import { Options } from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { LobyMixin } from './mixin'
import { VTextMarquee } from 'vue-text-marquee'
import ListItem from '@/components/loby/m-list-item.vue'
import { EventBuss } from '@/utils/constants'
import MobileSettingDialogView from '@/components/dialogs/m-setting-dialog-view.vue'
import BetHistory from '@/components/dialogs/history-view.vue'
import ChangePasswordView from '@/components/dialogs/change-pass-view.vue'
import AppSvg from '@/components/app-svg.vue'

@Options({
    components: {
        'marquee-text': VTextMarquee,
        'app-svg': AppSvg,
        ListItem,
        MobileSettingDialogView,
        BetHistory,
        ChangePasswordView
    }
})
export default class MobileLoby extends Mixins(LobyMixin) {
    noticeIsPaused = false
    @Getter('betCoin') betCoin!: number

    installApp(): void {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        this.emitter.emit(EventBuss.ON_ADD_TO_HOME_PAGE)
    }

    // get formattedBetCoin(): string {
    //     return toMoneyFormat(this.betCoin)
    // }
}
