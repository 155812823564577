
import { Device } from '@/store/types'
import { Options, Vue } from 'vue-class-component'
import { Getter } from 'vuex-class'
import PcHeader from './d-header.vue'
import MobileHeader from './m-header.vue'

@Options({
    components: {
        PcHeader,
        MobileHeader
    }
})
export default class HeaderView extends Vue {
    //#region Vuex
    @Getter('device') device!: Device

    onTriggerShowLimit(): void {
        this.$emit('on-show-limit')
    }

    //#endregion
}
