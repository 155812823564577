import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import Cookies from 'js-cookie'
import { Token } from '@/utils/constants'
import {
    getBrowserLanguage,
    getCookieValue,
    getDeviceOrientation,
    isMobile,
    setCookieValue
} from '@/utils'
import { AppState, Device, State } from '../types'
import { pingServer } from '@/api'

export enum AppMutation {
    SHOW_LOADING = 'SHOW_LOADING',
    HIDE_LOADING = 'HIDE_LOADING',
    SET_INIT_SOUND = 'SET_INIT_SOUND',
    SET_FULL_SCREEN = 'SET_FULL_SCREEN',
    SET_CHIP_VALAUE = 'SET_CHIP_VALAUE',
    SET_SOUND = 'SET_SOUND',
    SET_CHIP_INDEX = 'SET_CHIP_INDEX',
    RELOAD_ORIENTAION = 'RELOAD_ORIENTAION',
    SET_LANG = 'SET_LANG',
    SET_LINE = 'SET_LINE',
    APP_SET_LINE_STR = 'APP_SET_LINE_STR'
}

const state: AppState = {
    device: {
        display: isMobile() ? 1 : 0,
        // display: 0,
        size: { width: '', height: '' },
        orientation: getDeviceOrientation()
    },
    infoKey: Date.now(),
    customChipValue:
        parseInt(getCookieValue(Token.CUSTOM_CHIP_VALUE) || '') || 0,

    showLoading: false,
    userInteraction: 0,
    playSound: parseInt(getCookieValue(Token.SOUND) || '') || 1,

    isFullScreen: false,
    currentChipIndex:
        parseInt(getCookieValue(Token.CURRENT_CHIP_INDEX) || '') || -1,
    lang: getCookieValue(Token.LANG) || getBrowserLanguage(),
    line: parseInt(getCookieValue(Token.LINE) ?? '0'),
    lineStr: getCookieValue(Token.LINE_STR)?.b64decode() ?? ''
}

const mutations: MutationTree<AppState> = {
    [AppMutation.HIDE_LOADING](state) {
        state.showLoading = false
    },
    [AppMutation.SHOW_LOADING](state) {
        state.showLoading = true
    },
    [AppMutation.SET_INIT_SOUND](state) {
        state.userInteraction = 1
    },
    [AppMutation.SET_FULL_SCREEN](state, isFull: boolean) {
        state.isFullScreen = isFull
    },
    [AppMutation.SET_CHIP_VALAUE](state, value: number) {
        state.customChipValue = value
        setCookieValue(Token.CUSTOM_CHIP_VALUE, value.toString())
    },
    [AppMutation.SET_SOUND](state, num: number) {
        state.playSound = num
        setCookieValue(Token.SOUND, num.toString())
    },
    [AppMutation.SET_CHIP_INDEX](state, value: number) {
        state.currentChipIndex = value
        setCookieValue(Token.CURRENT_CHIP_INDEX, value.toString())
    },
    [AppMutation.RELOAD_ORIENTAION](state) {
        state.device.orientation = getDeviceOrientation()
    },
    [AppMutation.SET_LANG](state, payload: string) {
        state.lang = payload
        setCookieValue(Token.LANG, payload)
    },
    [AppMutation.SET_LINE](state, payload: number) {
        state.line = payload
        setCookieValue(Token.LINE, String(state.line))
    },
    [AppMutation.APP_SET_LINE_STR](state, payload: string) {
        state.lineStr = payload
        setCookieValue(Token.LINE_STR, payload.b64encode())
    }
}

const actions: ActionTree<AppState, State> = {
    showLoading({ commit }): void {
        commit(AppMutation.SHOW_LOADING)
    },
    hideLoading({ commit }): void {
        commit(AppMutation.HIDE_LOADING)
    },
    interActPage({ commit }): void {
        commit(AppMutation.SET_INIT_SOUND)
    },
    setFullScreen({ commit }, isFull: boolean): void {
        commit(AppMutation.SET_FULL_SCREEN, isFull)
    },
    setCustomChip({ commit }, value: number): void {
        commit(AppMutation.SET_CHIP_VALAUE, value)
    },
    setSound({ commit }, num: number) {
        commit(AppMutation.SET_SOUND, num)
    },
    setCurrentChipIndex({ commit }, chipIndex: number): void {
        commit(AppMutation.SET_CHIP_INDEX, chipIndex)
    },
    checkOrientation({ commit }) {
        commit(AppMutation.RELOAD_ORIENTAION)
    },
    pingServer(
        { commit },
        params: { line: number; url: string }
    ): Promise<string> {
        // console.log(params)
        return new Promise<string>((resolve, reject) => {
            const { line, url } = params
            pingServer(line, url)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    setLine({ commit }, num: number) {
        commit(AppMutation.SET_LINE, num)
    },
    setLineStr({ commit }, strLine: string) {
        commit(AppMutation.APP_SET_LINE_STR, strLine)
    }
}

const getters: GetterTree<AppState, State> = {
    device(state): Device {
        return state.device
    },
    isLoading(state): boolean {
        return state.showLoading
    },
    isSoundOn(state): boolean {
        return state.playSound === 1
    },
    userHasInteractToPage(state): boolean {
        return state.userInteraction !== 0
    },
    customChipValue(state): number {
        return state.customChipValue
    },
    isFullScreen(state): boolean {
        return state.isFullScreen
    },
    soundEffect(state): number {
        return state.playSound
    },
    currentChipIndex(state): number {
        return state.currentChipIndex
    },
    lang(state) {
        return state.lang
    },
    serverLine: state => state.line
}

export const app: Module<AppState, State> = {
    state,
    mutations,
    actions,
    getters
}
