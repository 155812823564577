
import { Device } from '@/store/types'
import { Options, Vue } from 'vue-class-component'
import { Getter } from 'vuex-class'
import PcLoby from './d-loby.vue'
import MobileLoby from './m-loby.vue'

@Options({
    components: {
        PcLoby,
        MobileLoby
    }
})
export default class Loby extends Vue {
    //#region Vuex
    @Getter('device') device!: Device

    //#endregion
}
