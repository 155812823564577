import { multibetTables } from '@/api/multidesk'
import { ResponseData } from '@/datamodel/response-data'
import { MultipleBetDeskInfo } from '@/datamodel/urlparams/MultipleBetDeskInfo'
import { getCookieValue, getValueByKey, setCookieValue } from '@/utils'
import { Token } from '@/utils/constants'
import Cookies from 'js-cookie'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { MultiViewState, State } from '../types'
import { CancelTokenSource } from 'axios'

export enum MultiViewMutations {
    SET_MULTIDESK_TABLES = 'SET_MULTIDESK_TABLES',
    SET_SELECTED_MULTIDESK_TABLES = 'SET_SELECTED_MULTIDESK_TABLES'
}

const state: MultiViewState = {
    tables:
        getCookieValue(Token.MULTIBET_DESK)
            ?.b64decode()
            .toObject<Array<number>>() || new Array<number>(),
    selectedTables: new Array<number>()
}

const actions: ActionTree<MultiViewState, State> = {
    setMultiDeskTables({ commit }, tables: Array<number>): void {
        commit(MultiViewMutations.SET_MULTIDESK_TABLES, tables)
    },
    setSelectedMultiDeskTables(
        { commit },
        selectedTables: Array<number>
    ): void {
        commit(MultiViewMutations.SET_SELECTED_MULTIDESK_TABLES, selectedTables)
    },
    getMultiViewTables(
        { commit },
        model: MultipleBetDeskInfo
    ): Promise<ResponseData> {
        return new Promise<ResponseData>((resolve, reject) => {
            multibetTables(model)
                .then(response => {
                    if (response.data.includes('err=')) {
                        resolve(
                            new ResponseData({
                                error:
                                    getValueByKey(response.data, 'err') || '2'
                            })
                        )
                    } else {
                        resolve(new ResponseData({ data: response.data }))
                    }
                })
                .catch(e => {
                    reject(e)
                })
        })
    }
}

const mutations: MutationTree<MultiViewState> = {
    [MultiViewMutations.SET_MULTIDESK_TABLES](state, tables: Array<number>) {
        state.tables = tables
        setCookieValue(Token.MULTIBET_DESK, JSON.stringify(tables).b64encode())
    },
    [MultiViewMutations.SET_SELECTED_MULTIDESK_TABLES](
        state,
        selectedTables: Array<number>
    ) {
        state.selectedTables = selectedTables
    }
}

const getters: GetterTree<MultiViewState, State> = {
    multiTablesDesk(state): Array<number> {
        return state.tables
    }
}

export const multiview: Module<MultiViewState, State> = {
    state,
    mutations,
    actions,
    getters
}
