
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'
import { Options, Vue } from 'vue-class-component'

@Options({
    components: {
        Carousel,
        Slide
    }
})
export default class BannerView extends Vue {
    slides = new Array<string>()
    selectedIndex = 0

    beforeMount(): void {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const img2 = require(`@/assets/imgs/1.jpeg`)
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const img1 = require(`@/assets/imgs/2.jpeg`)

        this.slides = new Array<string>()
        this.slides.push(img1)
        this.slides.push(img2)
    }

    changeSelectedIndex(e: { currentSlide: number }): void {
        this.selectedIndex = e.currentSlide
    }
}
