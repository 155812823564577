/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { TypePair } from './types'

/** Data Model On Creating A Library Collection
 * That has Key and Value Properties
 * Generic T
 */

//#region Dictionary
// export class Dictionary<T> implements Dictionary<T> {
//     // Items
//     private items: { [index: string]: T } = {}
//     // Number of item
//     private count = 0

//     private checkOwnProperty(obj: any, propertyName: string): boolean {
//         return (
//             obj &&
//             (typeof obj === 'object' || typeof obj === 'function') &&
//             Object.prototype.hasOwnProperty.call(obj, propertyName)
//         )
//     }

//     public ContainsKey(key: string, gh = true): boolean {
//         // return (this.items).hasOwnProperty(key);
//         if (!gh) {
//             console.log('let')
//         }
//         return this.checkOwnProperty(this.items, key)
//     }

//     public Count(): number {
//         return this.count
//     }

//     public Add(key: string, value: T) {
//         if (!this.checkOwnProperty(this.items, key)) this.count++

//         this.items[key] = value
//     }

//     public Remove(key: string): T {
//         const val = this.items[key]
//         delete this.items[key]
//         this.count--
//         return val
//     }

//     public Item(key: string): T {
//         return this.items[key]
//     }

//     public Keys(): string[] {
//         const keySet: string[] = []

//         for (const prop in this.items) {
//             if (this.checkOwnProperty(this.items, prop)) {
//                 keySet.push(prop)
//             }
//         }

//         return keySet
//     }

//     public Values(): T[] {
//         const values: T[] = []

//         for (const prop in this.items) {
//             if (this.checkOwnProperty(this.items, prop)) {
//                 values.push(this.items[prop])
//             }
//         }
//         return values
//     }
// }
//#endregion

/**
 * RoadMap Item
 */
export class RoadMapItem {
    type = 0
    size = 0
    pairs: number[] = []

    constructor(type = 0) {
        this.type = type
    }

    addSize() {
        this.size += 1
    }

    pop() {
        if (this.size > 0) this.size -= 1
    }
}
/** BaseRoadmapItem */
export class BaseRoadmapItem extends RoadMapItem {
    y: number
    x: number
    constructor(type: number, x: number, y: number) {
        super(type)
        this.x = x
        this.y = y
    }
}

/** BeadItems */
export class BeadItems {
    x: number
    y: number
    type: number
    pair = 0

    constructor(x: number, y: number, type: number, pair = 0) {
        this.x = x
        this.y = y
        this.type = type
        this.pair = pair
    }
}

/** BigItems */
export class BigItem extends BaseRoadmapItem {
    tieSize = 0
    hasTie = false
    pair = TypePair.NONE

    addTie() {
        this.tieSize += 1
    }

    hasPair() {
        return this.pair !== TypePair.NONE
    }
}

/** Roadmap Data */
export class RoadMapData {
    beads: BeadItems[] = []
    bigs: BigItem[] = []
    bigeyes: BaseRoadmapItem[] = []
    smalls: BaseRoadmapItem[] = []
    cockroachs: BaseRoadmapItem[] = []
    round = 0
    redCount = 0
    blueCount = 0
    tieCount = 0
    redPairCount = 0
    bluePairCount = 0
    gameType = 1
}

/** Roadmap Properties */
export class RoadMapProperties {
    width: number
    height: number
    colNum = 13
    colNumSecondary = 0
    colWidthSecondary = 0
    otherTypeXOffsetX = 0
    cockroachTypeOffserX = 0
    bigOffsetY = 0
    otherOffsetY = 0
    targetOffsetY = 0
    colWidth: number
    dpi: number
    ctx: CanvasRenderingContext2D
    fontSize = 0.7
    lineWidth = 1.5
    isPre = false

    constructor(
        context: CanvasRenderingContext2D,
        w: number,
        h: number,
        cw: number,
        dpi: number,
        cn?: number,
        isPre?: boolean
    ) {
        this.width = w
        this.height = h
        this.colWidth = cw
        this.colNum = cn || 13
        this.dpi = dpi
        this.ctx = context
        this.isPre = isPre || false
    }
}
