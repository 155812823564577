import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "timer-loading-bar-root",
  "data-fill": '100%',
  ref: "border"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", {
      class: ["fill", { ticking: _ctx.count > -1 && !_ctx.isReset }],
      ref: "fill"
    }, null, 2)
  ], 512))
}