
import { Action, Getter } from 'vuex-class'
import CalendarPicker from '@/components/calendar/calendar-picker.vue'
import { BetHistoryParams } from '@/datamodel/urlparams/BetHistoryParams'
import { BetLogDetailsParams } from '@/datamodel/urlparams/BetLogDetailsParams'
import { Device } from '@/store/types'
import { Options, Vue } from 'vue-class-component'
import { ResponseData } from '@/datamodel/response-data'
import { BetLog } from '@/datamodel/bet-log'
import { BetLogDeatails } from '@/datamodel/bet-log-details'
import { useI18n } from 'vue-i18n'

@Options({
    components: {
        CalendarPicker
    }
})
export default class HistoryView extends Vue {
    iN = useI18n().t
    isMobile = false
    showDetails = false
    startDate = new Date()
    endDate = new Date()
    selectedDate = null

    betLogs = new Array<BetLog>()
    betLogDetails = new Array<BetLogDeatails>()

    @Getter('userId') uid!: string
    @Getter('device') device!: Device

    @Action('showLoading') showLoading!: () => void
    @Action('hideLoading') hideLoading!: () => void
    @Action('getBetHistory') getBetHistory!: (
        model: BetHistoryParams
    ) => Promise<ResponseData>
    @Action('destroySession') destroySession!: () => void

    @Action('getBetHistoryDetails') getBetHistoryDetails!: (
        model: BetLogDetailsParams
    ) => Promise<ResponseData>

    //#region Vue Methods
    beforeMount(): void {
        this.isMobile = this.device.display === 1

        // const betlog = new BetLog()
        // betlog.mday = new Date().toISOString().substring(0, 10)
        // betlog.sumPointed = 0
        // betlog.count = 2
        // this.betLogs.push(betlog)

        // const betlogdet = new BetLogDeatails()
        // betlogdet.gameid = '121221'

        // this.betLogDetails.push(betlogdet)
    }

    mounted(): void {
        this.OnClickViewBetHistory()
    }
    //#endregion

    OnClickClose(): void {
        // EventBus.$emit(EventBusEvents.SHOW_DIALOGS, Dialog.HISTORY_VIEW)
        this.$emit('on-close')
    }

    OnStartDateChange(date: Date): void {
        this.startDate = date
    }

    OnEndDateChange(date: Date): void {
        this.endDate = date
    }

    OnClickViewBetHistory(): void {
        if (this.startDate && this.endDate) {
            this.showLoading()

            const model = new BetHistoryParams(
                this.uid,
                this.startDate.toISOString().substr(0, 10),
                this.endDate.toISOString().substr(0, 10)
            )

            this.getBetHistory(model)
                .then((response: ResponseData) => {
                    // EventBus.$emit(EventBusEvents.SHOW_LOADER, false)
                    this.hideLoading()
                    if (response.error) {
                        if (response.error === '2') {
                            this.destroySession()
                            this.$router.push({ name: 'login' })
                        }
                    } else {
                        if (response.data) {
                            this.betLogs = BetLog.ParseList(response.data)
                        }
                    }
                })
                .catch(() => {
                    // EventBus.$emit(EventBusEvents.SHOW_LOADER, false)
                    this.hideLoading()
                })
        }
    }

    OnClickLogsItem(mday: string): void {
        // this.showDetails = true
        this.betLogDetails = new Array<BetLogDeatails>()
        this.showDetails = true

        const model = new BetLogDetailsParams(mday, this.uid)

        this.showLoading()

        this.getBetHistoryDetails(model)
            .then((response: ResponseData) => {
                this.hideLoading()
                if (response.error) {
                    if (response.error === '2') {
                        this.destroySession()
                        this.$router.push({ name: 'login' })
                    }
                } else {
                    if (response.data) {
                        this.betLogDetails = BetLogDeatails.ParseList(
                            response.data
                        )
                    }
                }
            })
            .catch(() => {
                this.hideLoading()
            })
    }

    roundFixed2(_value = 0): number {
        return +_value.toFixed(2)
    }
}
