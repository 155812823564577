
import { ResponseData } from '@/datamodel/response-data'
import { ChangePassParams } from '@/datamodel/urlparams/ChangePassParams'
import { Device } from '@/store/types'
import { EventBuss } from '@/utils/constants'
import { Options, Vue } from 'vue-class-component'
import { useI18n } from 'vue-i18n'
import { Action, Getter } from 'vuex-class'

@Options({})
export default class ChangePassView extends Vue {
    iN = useI18n().t
    errorMessage = ''
    oldPass = ''
    newPass = ''
    confirmPass = ''

    isMobile = false

    //#region Store Getter
    @Getter('userId') uid!: string
    @Getter('device') device!: Device
    //#endregion

    //#region Store Action
    @Action('changePassword') changePassword!: (
        model: ChangePassParams
    ) => Promise<ResponseData>
    @Action('showLoading') showLoading!: () => void
    @Action('hideLoading') hideLoading!: () => void
    //#endregion
    //#region Vue Methods
    beforeMount(): void {
        this.isMobile = this.device.display === 1
    }
    //#endregion

    OnClickClose(): void {
        this.$emit('on-close')
    }
    OnCLickConfirm(): void {
        if (this.oldPass === '') {
            // this.errorMessage = '请输入旧密码的值'
            this.errorMessage = '原密码错误或新密码不一致'
            return
        }

        if (this.newPass === '') {
            // this.errorMessage = '请输入密码值'
            this.errorMessage = '原密码错误或新密码不一致'
            return
        }

        if (this.newPass !== this.confirmPass) {
            // this.errorMessage = '密码不匹配'
            this.errorMessage = '原密码错误或新密码不一致'
            return
        }

        this.showLoading()

        const model = new ChangePassParams(this.uid, this.oldPass, this.newPass)

        this.changePassword(model)
            .then(response => {
                this.hideLoading()
                if (response.error) {
                    // this.errorMessage = '失败的'
                    this.errorMessage = '原密码错误或新密码不一致'
                } else {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    this.emitter.emit(EventBuss.TOAST_MESSAGE, '修改成功')
                    this.OnClickClose()
                }
            })
            .catch(() => {
                this.errorMessage = '无法连接到服务器'
                this.hideLoading()
            })
    }
}
