import { ResponseData } from '@/datamodel/response-data'
import { Lang } from './constants'

/**
 * Get Display Orientation
 * @returns bolean
 */
export function isLandscape(): boolean {
    return window.innerWidth > window.innerHeight
    // return window.orientation === 90 || window.orientation === -90
}

/**
 * Check if It is A mobile device or not
 * @returns bolean
 */
export function isMobile(): boolean {
    if (
        navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
    ) {
        return true
    }
    return false
}
/**
 * Determine if its IOS Mobile or not
 * @returns bolean
 */
export function isIosMobile(): boolean {
    const _isIOSMobile = /iPhone|iPad|iPod/i.test(navigator.userAgent)
    if (_isIOSMobile) {
        return true
    }
    return false
}

export const Animation = {
    //No easing
    constant: (duration: number, range: number): number => {
        return duration / range
    },

    //Linear easing
    linear: (duration: number, range: number, current: number): number => {
        return ((duration * 2) / Math.pow(range, 2)) * current
    },

    //Quadratic easing
    quadratic: (duration: number, range: number, current: number): number => {
        return ((duration * 3) / Math.pow(range, 3)) * Math.pow(current, 2)
    }
}

/**
 * Get Value By key
 * @param responseStr
 * @param key
 * @param spliter
 */
export function getValueByKey(
    responseStr: string,
    key: string,
    spliter = '&'
): string | undefined {
    const keyValues = responseStr.split(spliter)
    let value

    for (let i = 0; i < keyValues.length; i++) {
        const item = keyValues[i]
        if (item.startsWith(`${key}=`)) {
            const keyValue = item.split('=')
            if (keyValue.length > 1) {
                value = keyValue[1]
            } else {
                value = undefined
                break
            }
        }
    }
    return value
}
/**
 * Get the Value of KeyValue
 * @param keyValue
 */
export function getValueOfKeyValue(keyValue: string): string | undefined {
    if (keyValue === '') return undefined
    const value = keyValue.split('=')
    if (value.length > 1) {
        return value[1]
    }
    return undefined
}

export function tabIndexToParams(tabIndex: number): number {
    // 0 all type
    // 1 baccarat only
    // 2 dragon tiger only
    if (tabIndex !== 0) return tabIndex

    return 0
}

export function toMoneyFormat(value: number): string {
    const amount = value.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
    return amount.replace('.00', '')
}

/**
 * Add Days from the date given
 * @param date Date to add
 * @param days number of days to add
 */
export function addDays(date: Date, min: number): number {
    // const result = new Date(date)
    // result.setDate(result.getDate() + days)
    // console.log(result.getTime())
    // return Number(result)
    // var futureDate = new Date(currentDate.getTime() + minutesToAdd*60000);
    const result = new Date(date.getTime() + min * 60000)

    return result.getTime()
}

/**
 * generate random number between min and max number
 * @param min smallest posible number
 * @param max maximum pasible number
 */
export function generateRandomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min
}

//#region Date Helpers
export function daysInMonth(iMonth: number, iYear: number): number {
    return 32 - new Date(iYear, iMonth, 32).getDate()
}
//#endregion

export function toResponseData(strData: string): ResponseData {
    if (strData.includes('err=')) {
        return new ResponseData({
            error: getValueByKey(strData, 'err') || '2'
        })
    } else {
        return new ResponseData({
            data: strData
        })
    }
}

export function abbreviateNumber(value: number): string {
    if (value.toString().length < 4) return value.toString()
    let result = ''
    if (value >= 1000) {
        const suffixes = ['', 'K', 'M', 'B', 'T']
        const suffixNum = Math.floor(('' + value).length / 3)
        let shortValue = value
        for (let precision = 2; precision >= 1; precision--) {
            shortValue = parseFloat(
                (suffixNum != 0
                    ? value / Math.pow(1000, suffixNum)
                    : value
                ).toPrecision(precision)
            )
            const dotLessShortValue = (shortValue + '').replace(
                /[^a-zA-Z 0-9]+/g,
                ''
            )
            if (dotLessShortValue.length <= 2) {
                break
            }
        }
        if (shortValue % 1 != 0) result = shortValue.toFixed(1)
        result = shortValue + suffixes[suffixNum]
    }
    return result
}

export function to24HoursFormat(d: Date): string {
    let month = d.getMonth() + 1 + ''
    const year = d.getFullYear() + ''
    let day = d.getDate() + ''
    let hours = d.getHours() + ''
    let minutes = d.getMinutes() + ''
    let seconds = d.getSeconds() + ''

    if (month.length === 1) month = '0' + month
    if (day.length === 1) day = '0' + day
    if (hours.length === 1) hours = '0' + hours
    if (minutes.length === 1) minutes = '0' + minutes
    if (seconds.length === 1) seconds = '0' + seconds

    return (
        [year, month, day].join('/') + ' ' + [hours, minutes, seconds].join(':')
    )
}

export function getDeviceOrientation(): 'portrait' | 'landscape' {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((window.screen as any).orientation) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (window.screen as any).orientation.type.includes('landscape')
            ? 'landscape'
            : 'portrait'
    }

    // iOS/safari
    // console.log(Math.abs(+window.orientation))
    return Math.abs(+window.orientation) === 90 ? 'landscape' : 'portrait'
}

/**
 * Get Item In LocalStorage
 * @param _key
 * @returns
 */
export function getCookieValue(_key: string) {
    return window.sessionStorage.getItem(_key)
}
/**
 * Set Item in LocalStorage
 * @param _key
 * @param _value
 */
export function setCookieValue(_key: string, _value: string) {
    window.sessionStorage.setItem(_key, _value)
}

/**
 * return browser language
 * @returns browser language
 */
export function getBrowserLanguage(): string {
    const lang = (navigator.language || 'zh-cn').split('-')[0]

    if (Lang.indexOf(lang) === -1) {
        return 'zh'
    }

    return lang
}
