import { createStore, Store, useStore as baseUseStore } from 'vuex'
import { app } from './module/app'
import { user, UserMutation } from './module/user'
import { desk } from './module/desk'
import { loby } from './module/loby'

import Cookies from 'js-cookie'
import { Token } from '@/utils/constants'
import { TableLimit } from '@/datamodel/types'
import { multiview } from './module/mutli-view'
import {
    AppState,
    DeskState,
    InjectionKey,
    LobyState,
    State,
    UserState
} from './types'

export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
    state: {
        version: '1.0.1',
        root: true
    },
    modules: {
        app,
        user,
        desk,
        loby,
        multiview
    },
    mutations: {
        [UserMutation.DELETE_SESSION](state) {
            // Cookies.remove(Token.USERNAME)
            // Cookies.remove(Token.SESSION)
            // Cookies.remove(Token.BET_COIN)
            // Cookies.remove(Token.CHIPS_VALUES)
            // Cookies.remove(Token.CURRENT_CHIP_INDEX)
            // Cookies.remove(Token.CUSTOM_CHIP_VALUE)
            // // Cookies.remove(Token.DESK_CURRENT)
            // Cookies.remove(Token.DESK_NOTICE)
            // Cookies.remove(Token.DESK_LIMIT)
            // Cookies.remove(Token.LIMITS)
            // Cookies.remove(Token.LOBY_NOTICE)

            const appState = app.state as AppState
            const userState = user.state as UserState
            const deskState = desk.state as DeskState
            const lobyState = loby.state as LobyState

            userState.sessionId = undefined
            userState.userId = undefined
            userState.betCoin = 0

            appState.customChipValue = 0
            appState.currentChipIndex = -1
            deskState.chipsValues = new Array<number>()
            // state.desk.desk = undefined
            deskState.notice = ''
            deskState.deskLimit = undefined
            lobyState.deskLimits = new Array<TableLimit>()
            lobyState.notice = ''

            appState.line = 0
            appState.lineStr = ''

            window.sessionStorage.clear()
        }
    }
})

// define your own `useStore` composition function
export function useStore(): Store<State> {
    return baseUseStore(key)
}
