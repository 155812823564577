import { ACT } from '@/utils/constants'
import { BaseParams } from './BaseParams'

export class ChangePassParams extends BaseParams {
    opwd: string
    npwd: string
    username: string

    constructor(username: string, oldpass: string, newpass: string) {
        super(ACT.CHANGE_PASS)
        this.opwd = oldpass
        this.npwd = newpass
        this.username = username
    }
}
