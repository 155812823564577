import { BaseParams } from './BaseParams'
import { ACT } from '@/utils/constants'

export class BetLogDetailsParams extends BaseParams {
    username: string
    sday: string
    constructor(start: string, username: string) {
        super(ACT.GET_LOG_DETAILS)
        this.sday = start
        this.username = username
    }
}
