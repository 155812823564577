import { getValueOfKeyValue } from '@/utils'

export class DeskData {
    deskId?: number
    result?: string
    gameId?: string
    currentTime = -1
    gameType?: number
    xue?: number
    ju?: number

    isReserved = false
    gameStatus = -1

    rawData = ''

    redBet = 0
    blueBet = 0
    tieBet = 0
    redPairBet = 0
    bluePairBet = 0
    redSuper6Bet = 0
    super6Bet = 0
    balance = 0
    hasBet = false
    hasBetOnSuper6 = false
    hasBetOnRedSuper6 = false
    oldwin!: string

    limit = { min: 0, max: 0 }

    static ParseList(strData: string): Array<DeskData> {
        const list = new Array<DeskData>()
        const convertedStrList = strData.split('$')
        convertedStrList.pop()

        convertedStrList.forEach(strItem => {
            list.push(this.Parse(strItem))
        })
        return list
    }

    static Parse(strData: string): DeskData {
        let deskData: DeskData | undefined = undefined

        deskData = new DeskData()
        deskData.rawData = strData

        const keyValues = strData.split('&')
        let numStr

        keyValues.forEach(item => {
            numStr = getValueOfKeyValue(item)
            if (item.startsWith('mdesk=')) {
                if (numStr !== undefined && deskData)
                    deskData.deskId = parseInt(numStr)
            } else if (item.startsWith('result=')) {
                if (deskData) deskData.result = numStr
            } else if (item.startsWith('GameID=')) {
                if (deskData) deskData.gameId = numStr
            } else if (item.startsWith('xztime=') && deskData) {
                if (numStr !== undefined)
                    deskData.currentTime = parseInt(numStr)
                else deskData.currentTime = -1
            } else if (item.startsWith('gameType=') && deskData) {
                if (numStr !== undefined) deskData.gameType = parseInt(numStr)
            } else if (item.startsWith('xue=') && deskData) {
                if (numStr !== undefined) deskData.xue = parseInt(numStr)
            } else if (item.startsWith('ju=') && deskData) {
                if (numStr !== undefined) deskData.ju = parseInt(numStr)
            } else if (item.startsWith('y_z=') && deskData) {
                if (numStr !== undefined) deskData.redBet = parseInt(numStr)
            } else if (item.startsWith('y_x=') && deskData) {
                if (numStr !== undefined) deskData.blueBet = parseInt(numStr)
            } else if (item.startsWith('y_h=') && deskData) {
                if (numStr !== undefined) deskData.tieBet = parseInt(numStr)
            } else if (item.startsWith('y_zd=') && deskData) {
                if (numStr !== undefined) deskData.redPairBet = parseInt(numStr)
            } else if (item.startsWith('y_xd=') && deskData) {
                if (numStr !== undefined)
                    deskData.bluePairBet = parseInt(numStr)
            } else if (item.startsWith('y_sz=') && deskData) {
                if (numStr !== undefined)
                    deskData.redSuper6Bet = parseInt(numStr)
            } else if (item.startsWith('y_ss=') && deskData) {
                if (numStr !== undefined) deskData.super6Bet = parseInt(numStr)
            } else if (item.startsWith('UserPoint=') && deskData) {
                if (numStr !== undefined) deskData.balance = parseInt(numStr)
            } else if (item.startsWith('baotai=') && deskData) {
                deskData.isReserved = numStr === '1'
            } else if (item.startsWith('gameStatus=') && deskData) {
                if (numStr !== undefined) deskData.gameStatus = parseInt(numStr)
            } else if (item.startsWith('oldwin=') && deskData) {
                if (numStr !== undefined) deskData.oldwin = numStr
            } else if (item.startsWith('upLimit=') && deskData) {
                if (numStr !== undefined) deskData.limit.max = parseInt(numStr)
            } else if (item.startsWith('downLimit=') && deskData) {
                if (numStr !== undefined) deskData.limit.min = parseInt(numStr)
            }
        })

        deskData.hasBet =
            deskData.redBet +
                deskData.blueBet +
                deskData.tieBet +
                deskData.redPairBet +
                deskData.bluePairBet +
                deskData.redSuper6Bet +
                deskData.super6Bet >
            0

        deskData.hasBetOnSuper6 = deskData.redSuper6Bet + deskData.super6Bet > 0
        deskData.hasBetOnRedSuper6 = deskData.redSuper6Bet > 0
        return deskData
    }
}
