
import { DeskMoreInfoData } from '@/datamodel/desk-more-info-data'
import { DeskData } from '@/datamodel/deskdata'
import { ResponseData } from '@/datamodel/response-data'
import { TableLimit } from '@/datamodel/types'
import { DeskMoreInfoModel } from '@/datamodel/urlparams/DeskMoreInfoModel'
import { Options, Vue } from 'vue-class-component'
import { useI18n } from 'vue-i18n'
import { Prop } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

@Options({})
export default class DListItemMultiView extends Vue {
    iN = useI18n().t
    @Prop({ default: -1 }) selectedIndex!: number
    @Prop({ default: -1 }) itemIndex!: number

    @Prop({ default: new Array<DeskData>() }) desks!: Array<DeskData>
    //#region Vuex Getter
    @Getter('tableLimits') tableLimits!: Array<TableLimit>
    //#endregion

    //#region Vuex Action
    @Action('showLoading') showLoading!: () => void
    @Action('hideLoading') hideLoading!: () => void
    @Action('getDeskOtherInfo') getDeskOtherInfo!: (
        model: DeskMoreInfoModel
    ) => Promise<ResponseData>
    @Action('setChipsValues') setChipsValues!: (
        chipvalues: Array<number>
    ) => void
    @Action('setCurrentDesk') setCurrentDesk!: (
        model: DeskData | undefined
    ) => void
    @Action('destroySession') destroySession!: () => void
    @Action('setDeskLimit') setDeskLimit!: (limit: TableLimit) => void
    @Action('setDeskNotice') setDeskNotice!: (notice: string) => void
    @Action('setMultiDeskTables')
    setMultiDeskTables!: (tables: Array<number>) => void
    //#endregion

    enterDesk(tableLimit: TableLimit): void {
        this.showLoading()
        const model = new DeskMoreInfoModel(0, tableLimit.Id)

        model.desk = this.desks[0].deskId

        this.getDeskOtherInfo(model)
            .then((response: ResponseData) => {
                this.hideLoading()
                if (response.error) {
                    if (response.error === '2') {
                        this.destroySession()
                        this.$router.push({ name: 'login' })
                    }
                } else {
                    const {
                        limit,
                        chipsVallue,
                        notice
                    } = DeskMoreInfoData.Parse(response.data || '')
                    const lm = limit
                    if (lm) {
                        lm.Id = model.xianId
                        this.setDeskLimit(lm)
                    }

                    if (chipsVallue) this.setChipsValues(chipsVallue)
                    if (notice) this.setDeskNotice(notice)

                    const tables = Array<number>()
                    this.desks.forEach((desk: DeskData) => {
                        tables.push(desk.deskId || 0)
                    })
                    this.setMultiDeskTables(tables)

                    // this.setCurrentDesk(this.desks[0])
                    this.$router.push({ name: 'multi-betting' })
                }
            })
            .catch(() => {
                this.hideLoading()
            })
    }
}
