
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

@Options({})
export default class CalendarPicker extends Vue {
    date: Date | null = null

    @Prop({ default: true })
    enabled!: boolean

    @Prop({ default: '' })
    placeHolder!: string

    @Prop({ default: null })
    value!: Date

    minDate: Date | null = null

    attrs = [
        {
            key: 'today',
            highlight: {
                color: 'purple',
                fillMode: 'outline',
                contentClass: 'italic'
            },
            dates: new Date()
        }
    ]

    beforeMount(): void {
        if (this.value) this.date = this.value

        const days = 49
        const date = new Date()
        const last = new Date(date.getTime() - days * 24 * 60 * 60 * 1000)
        const day = last.getDate()
        const month = last.getMonth() + 1
        const year = last.getFullYear()

        this.minDate = new Date(`${month}/${day}/${year}`)
    }

    setFocus(): void {
        const el = this.$refs['input'] as Element
        el.dispatchEvent(new Event('mouseover'))
    }

    @Watch('date', { immediate: false })
    onDateChange(value: Date | null): void {
        if (value) {
            this.$emit('on-change', value)
        }
    }
}
