
import { Mixins } from 'vue-property-decorator'
import { RoadMapData } from '../roadmap/data-model'
import { LobyListItemMixin } from './list-item-mixin'

export default class MobileListItem extends Mixins(LobyListItemMixin) {
    roadMapData: RoadMapData = new RoadMapData()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    OnRoadMapData(a: RoadMapData, b: RoadMapData, c: RoadMapData): void {
        this.roadMapData = a
    }

    get redCount(): number {
        if (this.roadMapData !== undefined) return this.roadMapData.redCount
        return 0
    }

    get blueCount(): number {
        if (this.roadMapData !== undefined) return this.roadMapData.blueCount
        return 0
    }

    get gameId(): string {
        if (this.deskData) return this.deskData.gameId || ''

        return ''
    }

    get greenCount(): number {
        if (this.roadMapData !== undefined) return this.roadMapData.tieCount
        return 0
    }

    get bluePairCount(): number {
        if (this.roadMapData !== undefined)
            return this.roadMapData.bluePairCount
        return 0
    }

    get redPairCount(): number {
        if (this.roadMapData != undefined) return this.roadMapData.redPairCount
        return 0
    }
}
