
import TimerLoadingBar from './timer-loading-bar.vue'
import { Options } from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import { LobyListItemMixin } from './list-item-mixin'

@Options({
    components: {
        TimerLoadingBar
    }
})
export default class DListItemView extends Mixins(LobyListItemMixin) {}
