
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({
    name: 'app-svg'
})
export default class extends Vue {
    @Prop({ required: true }) className!: string
    @Prop({ required: true }) id!: string
    @Prop({ required: true }) box!: string
    @Prop({ required: true }) fill!: string
    @Prop({ required: true }) stroke!: string
}
