import { DeskMoreInfoData } from '@/datamodel/desk-more-info-data'
import { DeskData } from '@/datamodel/deskdata'
import { ResponseData } from '@/datamodel/response-data'
import { TableLimit } from '@/datamodel/types'
import { DeskMoreInfoModel } from '@/datamodel/urlparams/DeskMoreInfoModel'
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import RoadMapView from '@/components/roadmap/index.vue'
import { useI18n } from 'vue-i18n'

@Options({
    components: {
        RoadMapView
    }
})
export class LobyListItemMixin extends Vue {
    iN = useI18n().t
    //#region  Prop
    // display 1 this diplay the normal list
    // diplay value > 1 this will display picrure view
    @Prop({ default: 1 })
    display!: number

    @Prop({ default: -1 })
    selectedIndex!: number
    @Prop({ default: -1 })
    itemIndex!: number
    @Prop({ default: undefined })
    deskData!: DeskData

    //#endregion

    readyToRenderMap = false
    deskResult = ''

    currentTime = -1
    deskTimer: number | boolean = false

    //#region Vuex Action
    @Action('getDeskOtherInfo') getDeskOtherInfo!: (
        model: DeskMoreInfoModel
    ) => Promise<ResponseData>

    @Action('destroySession') destroySession!: () => void
    @Action('setDeskLimit') setDeskLimit!: (limit: TableLimit) => void
    @Action('setDeskNotice') setDeskNotice!: (notice: string) => void
    @Action('setChipsValues') setChipsValues!: (
        chipvalues: Array<number>
    ) => void
    @Action('setCurrentDesk') setCurrentDesk!: (
        model: DeskData | undefined
    ) => void
    @Action('setMultiDeskTables')
    setMultiDeskTables!: (tables: Array<number>) => void

    @Action('showLoading') showLoading!: () => void
    @Action('hideLoading') hideLoading!: () => void
    //#endregion

    //#region Vuex Getter
    @Getter('tableLimits') tableLimits!: Array<TableLimit>
    @Getter('desks') desks!: Array<DeskData>
    //#endregion
    //#region Vue Methods
    mounted(): void {
        // if (this.display === 1) {
        // this.drawMap()
        this.readyToRenderMap = true
        // }
    }

    beforeUnmount(): void {
        this.StopDeskTimer()
    }

    //#endregion

    //#region Helpler Method
    enterDesk(limit: TableLimit): void {
        this.showLoading()
        const model = new DeskMoreInfoModel(this.deskData.deskId || 0, limit.Id)

        this.getDeskOtherInfo(model)
            .then((response: ResponseData) => {
                this.hideLoading()
                if (response.error) {
                    if (response.error === '2') {
                        this.destroySession()
                        this.$router.push({ name: 'login' })
                    }

                    this.hideLoading()
                } else {
                    const {
                        limit,
                        chipsVallue,
                        notice
                    } = DeskMoreInfoData.Parse(response.data || '')
                    const lm = limit

                    if (lm) {
                        lm.Id = model.xianId
                        this.setDeskLimit(lm)
                    }

                    // let chips = chipsVallue

                    // if (chips?.length === 0) {
                    //     // chips = [90, 50, 100, 500, 1000]
                    //     chips = getChipListBaseOnMinBet(limit?.Min || 0)
                    // }

                    if (chipsVallue) this.setChipsValues(chipsVallue)
                    if (notice) this.setDeskNotice(notice)

                    if (this.display === 1) {
                        this.setCurrentDesk(this.deskData)
                        this.$router.push({ name: 'game' })
                    } else {
                        const tables = Array<number>()
                        this.desks.forEach((desk: DeskData) => {
                            tables.push(desk.deskId || 0)
                        })
                        this.setMultiDeskTables(tables)
                        this.setCurrentDesk(undefined)
                        this.$router.push({ name: 'multi-view' })
                    }
                }
            })
            .catch(() => {
                this.hideLoading()
            })
    }

    StopDeskTimer(): void {
        if (typeof this.deskTimer === 'number') {
            clearInterval(this.deskTimer)
            this.deskTimer = false
            this.currentTime = -1
        }
    }
    //#endregion

    //#region Computed Properties
    get title(): string {
        if (!this.deskData) return ''
        if (this.deskData.gameType === 1) {
            return `百家乐 ${this.deskData.deskId} 号桌`
        } else {
            return `龙虎 ${this.deskData.deskId} 号桌`
        }
    }

    get timerClass(): string {
        if (!this.deskData) return ''

        if (this.currentTime > 20) {
            return 'green'
        } else if (this.currentTime <= 20 && this.currentTime > 10) {
            return 'orange'
        } else {
            return 'red'
        }
    }

    get xueAndJu(): string {
        if (!this.deskData) return '局数 0-0'

        return `局数 ${this.deskData.xue} - ${this.deskData.ju}`
    }
    //#endregion

    //#region Watchers
    @Watch('deskData', { immediate: true })
    OnDataChange(value: DeskData): void {
        if (value) {
            this.deskResult = value.result || ''
            if (value.currentTime > -1 && typeof this.deskTimer !== 'number') {
                this.currentTime = value.currentTime
                this.deskTimer = setInterval(() => {
                    if (this.currentTime !== -1) {
                        this.currentTime -= 1
                    } else {
                        this.StopDeskTimer()
                    }
                }, 1000)
            } else if (
                value.currentTime === -1 &&
                typeof this.deskTimer === 'number'
            ) {
                this.StopDeskTimer()
            }
        }
    }
    //#endregion
}
