
import { Device } from '@/store/types'
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

@Options({})
export default class SwitchControl extends Vue {
    isCheck = true
    @Prop({ default: '' })
    label!: string
    @Prop({ default: 1 })
    value!: number

    @Getter('device') device!: Device

    beforeMount(): void {
        this.isCheck = this.value === 1
    }

    OnValueChange(e: Event): void {
        const _val = (e.target as HTMLInputElement).checked ? 1 : 0
        this.$emit('change-value', _val)
    }
}
