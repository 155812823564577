import axios from 'axios'
import { getCookieValue } from '.'
import { Token } from './constants'
// import { store } from '@/store'

const service = axios.create({
    baseURL: process.env.API_URL + process.env.API_EXT,
    timeout: 7000
})

// request interceptor
service.interceptors.request.use(
    config => {
        const line = parseInt(getCookieValue(Token.LINE) ?? '0')

        if (line > 0) {
            if (line === 1) {
                config.baseURL = `${process.env.API_URL_LINE1}/${process.env.API_EXT}`
            } else if (line === 2) {
                config.baseURL = `${process.env.API_URL_LINE2}/${process.env.API_EXT}`
            } else if (line === 3) {
                config.baseURL = `${process.env.API_URL_LINE3}/${process.env.API_EXT}`
            }
        } else if (line === -1) {
            const customIp = getCookieValue(Token.LINE_STR)
            config.baseURL = `${customIp}/${process.env.API_EXT}`
        }

        return config
    },
    error => {
        console.log(error)
        return Promise.reject(error)
    }
)

// request response interceptor
service.interceptors.response.use(
    response => {
        return response
    },
    error => {
        // console.log(`error: ${error}`) // debug error
        return Promise.reject(error)
    }
)

export default service
