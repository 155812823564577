
import { Options } from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import { LobyMixin } from './mixin'
import BannerView from '@/components/loby/banner-view.vue'
import DListItemView from '@/components/loby/d-list-item-view.vue'

import HeaderView from '@/components/header/index.vue'
import BetHistory from '@/components/dialogs/history-view.vue'
import SettingView from '@/components/dialogs/setting-dialog-view.vue'
import ChangePasswordView from '@/components/dialogs/change-pass-view.vue'
import DListItemMultiView from '@/components/loby/d-list-item-multi-view.vue'

@Options({
    components: {
        HeaderView,
        BannerView,
        DListItemView,
        BetHistory,
        SettingView,
        ChangePasswordView,
        DListItemMultiView
    }
})
export default class PcLoby extends Mixins(LobyMixin) {}
