import { LobyState, State } from '../types'
import Cookies from 'js-cookie'
import { Token } from '@/utils/constants'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { TableLimit } from '@/datamodel/types'
import { MultipleDeskParams } from '@/datamodel/urlparams/MultipleDeskParams'
import { ResponseData } from '@/datamodel/response-data'
import { getAllDeskByGameType } from '@/api/loby'
import { getCookieValue, getValueByKey, setCookieValue } from '@/utils'

export enum LobyMutation {
    SET_TABLE_LIMITS = 'SET_TABLE_LIMITS',
    SET_LOBY_NOTICE = 'SET_LOBY_NOTICE'
}

const state: LobyState = {
    deskLimits:
        getCookieValue(Token.LIMITS)
            ?.b64decode()
            .toObject<Array<TableLimit>>() || new Array<TableLimit>(),
    notice: getCookieValue(Token.LOBY_NOTICE)?.b64decode() || '',
    infoKey: new Date().getDate()
}

const mutations: MutationTree<LobyState> = {
    [LobyMutation.SET_TABLE_LIMITS](state, limits: Array<TableLimit>) {
        state.deskLimits = new Array<TableLimit>()
        limits.forEach(limit => {
            state.deskLimits.push(limit)
        })

        setCookieValue(Token.LIMITS, JSON.stringify(limits).b64encode())
    },
    [LobyMutation.SET_LOBY_NOTICE](state, notice: string) {
        setCookieValue(Token.LOBY_NOTICE, notice.b64encode())
        state.notice = notice
        state.infoKey = new Date().getDate()
    }
}

const actions: ActionTree<LobyState, State> = {
    setLobyNotice({ commit }, notice: string): void {
        commit(LobyMutation.SET_LOBY_NOTICE, notice)
    },
    setTableLimits({ commit }, limits: Array<TableLimit>) {
        commit(LobyMutation.SET_TABLE_LIMITS, limits)
    },
    getMultipleDesk(
        { commit },
        model: MultipleDeskParams
    ): Promise<ResponseData> {
        return new Promise<ResponseData>((resolve, reject) => {
            getAllDeskByGameType(model)
                .then(response => {
                    if (response.data.includes('err=')) {
                        resolve(
                            new ResponseData({
                                error:
                                    getValueByKey(response.data, 'err') || '2'
                            })
                        )
                    } else {
                        resolve(
                            new ResponseData({
                                data: response.data,
                                optional: model.gameType
                            })
                        )
                    }
                })
                .catch(() => {
                    reject()
                })
        })
    }
}

const getters: GetterTree<LobyState, State> = {
    lobyNotice(state): string {
        return state.notice
    },
    lobyInfoKey(state): number {
        return state.infoKey
    },
    tableLimits(state): Array<TableLimit> {
        return state.deskLimits
    }
}

export const loby: Module<LobyState, State> = {
    state,
    mutations,
    actions,
    getters
}
