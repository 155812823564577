import axios, { AxiosPromise } from 'axios'
import service from '@/utils/request'

export const AxiosSource = { Source: axios.CancelToken.source() }

/**
 * Ping Test
 * @param line
 * @param url
 * @returns
 */
export function pingServer(line: number, url: string): AxiosPromise<string> {
    let api = ''

    if (line === -1) {
        api = `${url}/${process.env.API_EXT}`
    } else if (line === 1) {
        api = `${process.env.API_URL_LINE1}/${process.env.API_EXT}`
    } else if (line === 2) {
        api = `${process.env.API_URL_LINE2}/${process.env.API_EXT}`
    } else if (line === 3) {
        api = `${process.env.API_URL_LINE3}/${process.env.API_EXT}`
    }

    const params = {
        skey: process.env.SKEY,
        act: 0,
        jm: 0
    }

    return service({
        baseURL: api,
        method: 'GET',
        params
    })
}
