import { State, UserState } from '../types'
import Cookies from 'js-cookie'
import { Token } from '@/utils/constants'
import { Module } from 'vuex'
import { GetterTree } from 'vuex'
import { ActionTree } from 'vuex'
import { MutationTree } from 'vuex'
import {
    betHistory,
    betHistoryDetails,
    changePassword,
    login,
    logout,
    trialLogin
} from '@/api/user'
import {
    getCookieValue,
    getValueByKey,
    setCookieValue,
    toResponseData
} from '@/utils'
import { LoginParam } from '@/datamodel/urlparams/LoginParams'
import { BetHistoryParams } from '@/datamodel/urlparams/BetHistoryParams'
import { ResponseData } from '@/datamodel/response-data'
import { BetLogDetailsParams } from '@/datamodel/urlparams/BetLogDetailsParams'
import { LogoutParams } from '@/datamodel/urlparams/LogoutParams'

import { ChangePassParams } from '@/datamodel/urlparams/ChangePassParams'

export enum UserMutation {
    SET_USER_ID = 'SET_USER_ID',
    SET_SESSION = 'SET_SESSION',
    DELETE_SESSION = 'DELETE_SESSION',
    SET_BET_COIN = 'SET_BET_COIN'
}

const state: UserState = {
    sessionId: getCookieValue(Token.SESSION)?.b64decode() || undefined,
    // Cookies.get(Token.SESSION)?.b64decode(),
    betCoin: parseInt(getCookieValue(Token.BET_COIN) || '0'),
    //parseInt(Cookies.get(Token.BET_COIN)?.b64decode() || '0') ||
    userId: getCookieValue(Token.USERNAME)?.b64decode() || undefined
    // Cookies.get(Token.USERNAME)?.b64decode()
}

const mutations: MutationTree<UserState> = {
    [UserMutation.SET_USER_ID](state, userId: string) {
        if (userId !== undefined || userId !== '') {
            setCookieValue(Token.USERNAME, userId.b64encode())
        }
        state.userId = userId
    },
    [UserMutation.SET_SESSION](state, sessionId: string) {
        if (sessionId !== undefined || sessionId !== '') {
            setCookieValue(Token.SESSION, sessionId.b64encode())
        }
        state.sessionId = sessionId
    },
    [UserMutation.SET_BET_COIN](state, betCoin: number) {
        setCookieValue(Token.BET_COIN, betCoin.toString())
        state.betCoin = betCoin
    }
}

const actions: ActionTree<UserState, State> = {
    authenticateUser({ commit }, model: LoginParam): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            login(model)
                .then(response => {
                    // check if there is a error
                    if (response.data.includes('err=')) {
                        const errorCode = getValueByKey(response.data, 'err')
                        let errorMessage = ''
                        if (errorCode === '-1') {
                            errorMessage = '参数传递错误'
                        } else if (errorCode === '-2') {
                            errorMessage = '用户名或密码输入错误'
                            // '用户或密码错误'
                        } else if (errorCode === '-3') {
                            errorMessage = '账号或上级代理被锁定'
                        } else if (errorCode === '0') {
                            errorMessage = '未知错误'
                        } else {
                            // -4
                            errorMessage = '账号已经登录，请稍后尝试'
                        }
                        reject(errorMessage)
                    } else {
                        resolve(response.data)
                    }
                })
                .catch(() => {
                    reject('网络错误')
                })
        })
    },
    trialoLogin(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            trialLogin()
                .then(response => {
                    if (response.data.includes('err=')) {
                        const errorCode = getValueByKey(response.data, 'err')
                        let errorMessage = ''
                        if (errorCode === '-1') {
                            errorMessage = '参数传递错误'
                        } else if (errorCode === '-2') {
                            errorMessage = '用户名或密码或余额输入错误'
                            // '用户或密码错误'
                        } else if (errorCode === '-3') {
                            errorMessage = '账号或上级代理被锁定'
                        } else if (errorCode === '0') {
                            errorMessage = '未知错误'
                        } else {
                            // -4
                            errorMessage = '账号已经登录，请稍后尝试'
                        }
                        reject(errorMessage)
                    } else {
                        resolve(response.data)
                    }
                })
                .catch(() => {
                    reject('网络错误')
                })
        })
    },
    destroySession({ commit }): void {
        commit(UserMutation.DELETE_SESSION)
    },
    setBetCoin({ commit }, betCoin: number): void {
        commit(UserMutation.SET_BET_COIN, betCoin)
    },
    createSession({ commit }, session: SessionModel): void {
        commit(UserMutation.SET_USER_ID, session.uid)
        commit(UserMutation.SET_SESSION, session.sid)
    },
    getBetHistory({ commit }, model: BetHistoryParams): Promise<ResponseData> {
        return new Promise<ResponseData>((resolve, reject) => {
            betHistory(model)
                .then(response => {
                    resolve(toResponseData(response.data))
                })
                .catch(() => {
                    reject()
                })
        })
    },
    getBetHistoryDetails(
        { commit },
        model: BetLogDetailsParams
    ): Promise<ResponseData> {
        return new Promise<ResponseData>((resolve, reject) => {
            betHistoryDetails(model)
                .then(response => {
                    resolve(toResponseData(response.data))
                })
                .catch(() => {
                    reject()
                })
        })
    },
    changePassword({ commit }, model: ChangePassParams): Promise<ResponseData> {
        return new Promise<ResponseData>((resolve, reject) => {
            changePassword(model)
                .then(response => {
                    if (response.data.includes('err=')) {
                        resolve(
                            new ResponseData({
                                error:
                                    getValueByKey(response.data, 'err') || '2'
                            })
                        )
                    } else {
                        resolve(
                            new ResponseData({
                                data: response.data
                            })
                        )
                    }
                })
                .catch(() => {
                    reject()
                })
        })
    },
    logOut({ commit }, userId: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            logout(new LogoutParams(userId))
                .then(() => {
                    commit(UserMutation.DELETE_SESSION)
                    resolve()
                })
                .catch(() => {
                    reject()
                })
        })
    }
}

const getters: GetterTree<UserState, State> = {
    sessionId(state): string | undefined {
        return state.sessionId
    },
    userId(state): string | undefined {
        return state.userId
    },
    betCoin(state): number {
        return state.betCoin
    }
}

export const user: Module<UserState, State> = {
    state,
    mutations,
    actions,
    getters
}
