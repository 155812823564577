
import { Options } from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import { HeaderMixin } from './mixin'
import AppSvg from '@/components/app-svg.vue'

@Options({
    components: {
        'app-svg': AppSvg
    }
})
export default class PcHeader extends Mixins(HeaderMixin) {}
