import { MultipleDeskParams } from '@/datamodel/urlparams/MultipleDeskParams'
import { AxiosPromise } from 'axios'
import service from '@/utils/request'
import { AxiosSource } from '.'

/**
 * Get Loby Data By Game Type
 * @param model
 */
export function getAllDeskByGameType(
    model: MultipleDeskParams
): AxiosPromise<string> {
    return service({
        method: 'POST',
        params: model,
        cancelToken: AxiosSource.Source.token
    })
}
