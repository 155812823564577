export class Sound {
    src: string

    /**
     *
     */
    constructor(fileName: string) {
        this.src = require(`@/assets/sounds/${fileName}.mp3`)
    }
}
