import { ACT } from '@/utils/constants'
import { BaseParams } from './BaseParams'

export class LogoutParams extends BaseParams {
    username: string
    constructor(username: string) {
        super(ACT.LOG_OUT)
        this.username = username
    }
}
